.container {
  background-color: #fff;
  position: fixed;
  inset: 0;
  top: 0;
  left: 0;
  padding: 0 2rem 4rem;
  overflow: scroll;
  text-align: center;
}

.wrapper {
  max-width: 770px;
  margin: 0 auto;
}

.cover img {
  width: 100%;
  vertical-align: top;
}

.cover {
  width: 100%;
}

.title {
  font-size: 2rem;
  margin: 4rem 0 3rem;
  font-weight: bold;
}

.contents {
  margin-top: 3rem;
}

.section {
  font-size: 1.4rem;
}

.sectionTitle {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.section + .section {
  margin-top: 4rem;
}

.item + .item {
  margin-top: 1.5rem;
}

.special {
  margin-top: 2rem;
  margin-bottom: 2.4rem;
}

.example {
  margin: 3rem 0 1.5rem;
  font-weight: bold;
  font-size: 1.7rem;
}

.specialSection.specialSection.specialSection {
  margin-top: 1.8rem;
}

.sectionGal {
  display: flex;
  gap: 2rem;
}

.sectionGal img {
  width: 100%;
}
