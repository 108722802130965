.container {
  padding: 0 2rem;
  height: 7rem;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}

.prevButton {
  border: none;
  background-color: inherit;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  z-index: 1;
}

.title {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  left: 0;
  right: 0;
}
