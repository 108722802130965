@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");

  font-style: normal;
}

@font-face {
  font-family: "Pretendard-extraBold";
  src: url("../assets/pretendard/Pretendard-ExtraBold.woff") format("woff");
  font-weight: normal;
}

:root {
  font-size: 62.5%;
  font-family: "Pretendard-Regular";
}
