.container {
  position: relative;
  overflow: hidden;
}

.contents {
  /* background-color: var(--gray-100); */
  background-color: white;
  min-height: 90vh;
  padding-bottom: 9rem;
}

.contents::-webkit-scrollbar {
  display: none;
}

.sliderContainer {
  position: relative;
  background-color: #f5f5f5;
  /* height: 150px; */
  aspect-ratio: 21 / 8;
  margin-bottom: 20px;
}

.sliderCountTag {
  position: absolute;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 1rem;
  bottom: 0.5rem;
  right: 1rem;
}

.slider {
  position: relative;
}

.slider img {
  width: 100%;
  height: 100%;
}

.banner {
  display: block;
}

.banner img {
  border-radius: 1rem;
  vertical-align: top;
  width: 100%;
}

/* card */
.card {
  background-color: #fff;
  box-shadow: 0 0.2rem 0.3rem 0.01rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  gap: 2rem;
  height: 16rem;
}

.card + .card {
  margin-top: 2rem;
  border-radius: 1rem;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.cardCover {
  background-color: #38b6ff;
  width: 13.9rem;
  border-radius: 1rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.cardCover img {
  width: 100%;
  vertical-align: top;
  position: relative;
  z-index: -1;
}

.cardContents {
  flex-grow: 1;
  min-width: 0;
}

.cardTitle {
  font-size: 1.8rem;
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardDescription {
  font-size: 1.5rem;
  line-height: 1.3;
  -webkit-line-clamp: 4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.cardFooter {
  margin-top: 1rem;
  text-align: right;
}

.cardRating {
  font-size: 1.4rem;
  border: 1px solid var(--gray-300);
  padding: 0.4rem 0.8rem;
  border-radius: 1.3rem;
  position: absolute;
  z-index: 2;
  bottom: 1.7rem;
  left: 50%;
  background-color: #ffffffc6;
  color: var(--gray-900);
  transform: translateX(-50%);
}

.moreBtn {
  margin: 1rem 0 1.6rem;
  text-decoration: none;
  display: block;
  color: var(--black);
  font-size: 1.2rem;
  text-align: right;
}

.sliderTag {
  position: absolute;
  background-color: rgba(217, 217, 217, 0.5);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  z-index: 6;
  bottom: 2.2rem;
  right: 2.6rem;
}

.contentsPadding {
  padding-top: 1rem;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
  padding: 0 2rem;
}

.categoryBtns {
  display: flex;
  gap: 0.7rem;
}

.categoryBtn {
  border: 0.98px solid #f4f4f4;
  border-radius: 2.8rem;
  font-size: 1.2rem;
  padding: 0.6rem 0.8rem;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #000;
}

.categoryBtn.isSelected {
  background-color: #ff6e05;
  color: #fff;
}

.controlBtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #000;
  text-align: center;
}

.controlValue {
  font-size: 1.3rem;
}

.dropDown {
  position: absolute;
  top: 2rem;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 0.4rem;
  background-color: #fff;
}

.dropDown button {
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 0.6rem 0;
  font-size: 1.16rem;
}

.dropDown button:hover {
  background-color: aliceblue;
}

/* .controlBtn::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background-color: currentColor;
  display: inline-block;
  border-radius: 50%;
} */

button {
  padding: 0;
}
.option {
  display: flex;
  gap: 0.5rem;
  position: relative;
  z-index: 9999;
}

.expand {
  cursor: pointer;
}

.cardList {
  display: grid;
  grid-template-columns: repeat(2, minmax(10rem, 50%));
  gap: 1rem;
  padding: 0 2rem;
}

@media (max-width: 365px) {
  .categoryBtn {
    font-size: 1rem;
  }

  .option * {
    font-size: 1rem;
  }
}
