.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 3rem;
  z-index: 9999;
  width: 34rem;
  box-sizing: border-box;
  border-radius: 2rem;
}

.deem {
  position: fixed;
  background-color: #000;
  inset: 0;
  z-index: 9998;
  opacity: 0.2;
  width: 41rem;
  left: 50%;
  transform: translateX(-50%);
}

.closeBtn {
  margin-left: auto;
  display: block;
  cursor: pointer;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.item {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-weight: bold;
  font-size: 1.3rem;
}

.item + .item {
  margin-top: 1rem;
}

@media (max-width: 410px) {
  .deem {
    width: 100%;
  }
}
