.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 3rem 3.5rem;
  z-index: 9999;
  width: 34rem;
  box-sizing: border-box;
  border-radius: 2rem;
  /* text-align: center; */
  font-size: 1.4rem;
}

.deem {
  position: fixed;
  background-color: #000;
  inset: 0;
  z-index: 9999;
  opacity: 0.2;
  width: 41rem;
  left: 50%;
  transform: translateX(-50%);
}

.btnContainer {
  margin-top: 2.4rem;
  margin-left: auto;
  width: fit-content;
}

.btnContainer button {
  font-weight: bold;
  cursor: pointer;
}

.message {
  width: fit-content;
  margin: 0 auto;
  line-height: 1.3;
  font-size: 1.5rem;
}

@media (max-width: 410px) {
  .deem {
    width: 100%;
  }
}
