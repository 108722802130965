.container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 3rem;
  padding: 0 1.7rem;
  height: 7.9rem;
  position: fixed;
  background-color: #fff;
  top: 0;
  width: 41rem;
  z-index: 1000;
  box-sizing: border-box;
}

.title {
  font-size: 2.2rem;
  font-weight: 900;
  flex-shrink: 0;
  font-family: Pretendard-extraBold;
  cursor: pointer;
}

.searchContainer {
  width: 78%;
  position: relative;
}

.search {
  /* border: 1px solid #c8c8c8; */
  border: 0;
  border-radius: 2rem;
  width: 100%;
  padding: 1rem 2.9rem;
  box-sizing: border-box;
  background-color: #f5f5f5;
  color: #a3a3a3;
}

.search::placeholder {
  font-size: 1.1rem;
}

.searchIcon {
  position: absolute;
  right: 2.9rem;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 0.4rem;
  background-color: #f5f5f5;
}

.search:focus {
  outline: none;
}

.controlBtn {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.controlIcon {
  cursor: pointer;
}

@keyframes stretch {
  0% {
    margin-right: -45%;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes deemFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes deemFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes shrink {
  0% {
    margin-right: 0;
  }

  100% {
    margin-right: -45%;
  }
}

/* sidebar */
.sidebar {
  background-color: var(--blue-400);
  position: absolute;
  right: 0;
  animation: stretch 0.3s ease-out;
  width: 48%;
  top: 0;
  bottom: 0;
  z-index: 2000;
  padding: 2rem;
  box-sizing: border-box;
}

.sidebarDisappear {
  animation: shrink 0.3s ease-out;
}

.deem {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1500;
  top: 0;
  animation: deemFadeIn 0.3s ease-out;
}

.deemDisappear {
  animation: deemFadeOut 0.3s ease-out;
}

.closeBtn {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.sidebarTitle {
  font-size: 2.1rem;
  margin-bottom: 3rem;
  font-weight: bold;
  text-align: center;
}

.sidebarContents {
  margin-top: 9rem;
  padding: 0 1.4rem;
}

.sidebarListItem + .sidebarListItem {
  margin-top: 1.6rem;
}

.sidebarListItem span {
  text-align: center;
  display: inline-block;
  color: #868686;
  border: 0.1rem solid;
  background-color: #fff;
  border-radius: 3.6rem;
  width: 100%;
  font-size: 1.4rem;
  padding: 1.4rem 0;
  cursor: pointer;
}

.sidebarListItem.selected span {
  color: var(--blue-200);
}

.sidebarBtn {
  margin-top: 2.5rem;
  width: 70%;
  display: block;
  margin: 2.5rem auto 0;
  color: #fff;
  background-color: var(--blue-300);
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  padding: 1rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
}

.recommendedParagraphContainer {
  position: absolute;
  top: 50%;
  left: 10%;
  font-size: 1.3rem;
  height: 2rem;
  overflow: hidden;
  transform: translateY(-50%);
}

.test {
  position: relative;
  transition: all 1s;
  color: #aaa;
}

.recommendedParagraph {
  line-height: 2rem;
}

/* 
  41rem이 410px로 안되는 이유?
  우선, px로 하고 나중에 고치기
*/
@media (max-width: 410px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .recommendedParagraphContainer {
    font-size: 1rem;
  }
}
