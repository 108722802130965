.contents {
  background-color: #fff;
  padding-bottom: 10rem;
  padding: 0 3rem 10rem;
}

.header {
  display: flex;
  padding: 2rem 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profileTitle {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
}

.profileRole {
  font-size: 1.3rem;
  color: #939393;
}

.gpsBtn,
.bookmarkBtn {
  cursor: pointer;
}

.starsContainer {
  width: fit-content;
  margin: 2rem auto;
}

.review {
  /* max-width: 28rem; */
  width: 100%;
  font-size: 1.4rem;
  margin: 0 auto;
  line-height: 1.4;
  padding: 2.4rem;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.3rem 0.25rem rgba(0, 0, 0, 0.1);
}

.btnContainer{
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.linkBtn {
  /* margin: 3rem auto; */
  display: block;
}

.linkBtn a {
  color: inherit;
  text-decoration: none;
  background-color: #e1e1e1;
  font-weight: bold;
  padding: 1.2rem 1.6rem;
  border-radius: 2rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.pictureContainer {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  height: 34rem;
}

.pictureDeem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 5;
}

.pictureContainer img {
  width: 100%;
  height: 100%;
  /* vertical-align: top; */
  object-fit: cover;
  position: relative;
}

.sliderTag {
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
  background-color: rgba(217, 217, 217, 0.5);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  z-index: 6;
}

.btns {
  display: flex;
  gap: 1rem;
}

.likeBtn{
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #6B82FF33;
  border-radius: 2rem;
  box-shadow: 0px 2px 2px 0px #00000040;
  padding: 1rem 3rem;
  margin: 3.5rem auto 0;
  cursor: pointer;
}


@media (max-width: 397px){
.linkBtn a {
  padding: 0.8rem 1.2rem;
  font-size: 1rem;

}
}
