.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10% 0;
}

@keyframes move {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.cover {
  flex-grow: 1;
  aspect-ratio: 1;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.6;
  animation: 1s move;
}

.kakaoLoginBtn {
  cursor: pointer;
  padding: 0 2rem;
}

.kakaoLoginBtn img {
  width: 100%;
}

.description {
  text-align: center;
  margin: 2rem 0;
  font-size: 1.7rem;
}
