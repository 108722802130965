.containerForScroll {
  height: 100vh;
  overflow: scroll;
}

.containerForScroll::-webkit-scrollbar {
  display: none;
}

.container {
  padding: 2rem 0 0;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.searchContainer {
  width: 90%;
  margin: 0 auto;
  background-color: #f3f3f3;
  box-sizing: border-box;
  border-radius: 2rem;
  /* overflow: hidden; */
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
}

.search {
  background-color: #f3f3f3;
  padding: 1.8rem 1rem;
  border: none;
  color: #7a7a7a;
  font-size: 1.5rem;
  width: 85%;
  flex-grow: 1;
}

.search:focus {
  outline: none;
}

.buttonsContainer {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
}

.buttonsContainer.hide {
  display: none;
}

.buttonsContainer button {
  /* display: block; */
  background-color: inherit;
  border: none;
  width: 50%;
  background-color: #f3f3f3;
  padding: 2rem 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
}

.listArea {
  margin-top: 2rem;
  /* height: 85vh; */
  /* background-color: #d9d9d9; */
  /* position: relative; */
  flex-grow: 1;
  padding: 0 3rem 4rem;
}

.listArea.hide {
  display: none;
}

.notFound.hide {
  display: none;
}

.selectedBox {
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  bottom: 3rem;
  width: 65%;
  left: 50%;
  padding: 2rem 4rem;
  box-sizing: border-box;
  transform: translateX(-50%);
  border-radius: 2rem;
}

.selectedBoxTitle {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.selectedBoxAddress {
  font-size: 1.3rem;
}

.submitBtn {
  display: block;
  border-radius: 1.5rem;
  background-color: #000;
  color: #fff;
  margin: 2.9rem auto 0;
  padding: 1.1rem 0;
  width: 58%;
  font-size: 1.5rem;
  cursor: pointer;
}

.prevBtn {
  background-color: #ebebeb;
  color: #000;
  margin-top: 1rem;
}

.submitBtn a {
  display: block;
  width: 100%;
  height: 100%;
}

.notFound {
  text-align: center;
}

.notFoundDescription {
  font-size: 1.6rem;
  line-height: 1.8;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 2rem;
  gap: 4rem;
}

.headerTitle {
  font-size: 1.7rem;
}

.item {
  cursor: pointer;
}

.item + .item {
  margin-top: 2.4rem;
}

.itemTitle {
  font-size: 1.5rem;
}

.itemAddress {
  margin-top: 0.7rem;
  font-size: 1.3rem;
  color: #797979;
}
