:root {
  --black: #000;
  --yellow: #ffd600;
  --green: #6cff83;
  --orange: #ffb23f;
  --red: #ff0c0c;
  --blue-100: #93b1ff;
  --blue-200: #2f81fd;
  --blue-300: #005be3;
  --blue-400: #eff5ff;
  --gray-100: #f3f3f3;
  --gray-200: #e1e1e1;
  --gray-300: #d7d7d7;
  --gray-400: #d8d8d8;
  --gray-500: #d9d9d9;
  --gray-600: #a0a0a0;
  --gray-700: #9b9b9b;
  --gray-800: #939393;
  --gray-900: #727272;
}
