.badge {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* padding: 1rem 1.5rem; */
}

.badge.small {
  width: 2.1rem;
  height: 2.1rem;
}

.FRESHMAN {
  background-color: var(--gray-500);
}

.BACHELOR {
  background-color: var(--blue-100);
}

.MASTERS {
  background-color: var(--green);
}

.DOCTORATE {
  background-color: var(--orange);
}

.PROFESSOR {
  background-color: var(--red);
}
