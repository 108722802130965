.container {
  position: relative;
}

.contents {
  padding: 0 4rem;
  /* background-color: #fff; */
  position: relative;
  z-index: 100;
  top: -0.6rem;
}

.searchShopBtn {
  background-color: #fff;
  width: 100%;
  border-radius: 1.2rem;
  box-shadow: 0 5px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem 0;
  color: #9b9b9b;
  margin-bottom: 2.4rem;
  cursor: pointer;
}

.searchShopBtn span {
  font-size: 1.6rem;
  font-family: "pretendard-regular";
}

.pictureBtn {
  background-color: #f7f7f7;
  border-radius: 2rem;
  color: #9b9b9b;
  font-size: 1.6rem;
  height: 26rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
  overflow: hidden;
}

.pictureSlider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  transition: all 0.2s;
}

.pictureDeem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.25;
  z-index: 5;
}

.pictureAddBtn {
  position: absolute;
  border: none;
  top: 1.5rem;
  right: 1.5rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  z-index: 6;
  cursor: pointer;
}

.sliderTag {
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
  background-color: rgba(217, 217, 217, 0.5);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  z-index: 6;
}

/*rating  */
.ratingInput {
  margin: 2rem 0;
  text-align: center;
  color: #9b9b9b;
  font-size: 1.6rem;
}

.stars {
  margin-top: 1rem;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.ratingStar {
  content: "";
  width: 2.5rem;
  height: 5rem;
  display: inline-block;
  background-image: url("../../assets/icons/stars.svg");
  background-position-x: 5rem;
}

.ratingStar:nth-child(even) {
  background-position-x: 2.5rem;
}

.ratingStar.selected_left {
  background-position: 10rem;
}

.ratingStar.selected_right {
  background-position-x: 7.5rem;
}

/* textarea */

.textareaContainer {
  position: relative;
  background-color: #f7f7f7;
  /* border: 1px solid #d8d8d8; */
  border-radius: 2rem;
  overflow: hidden;
  height: 17rem;
}

.textarea {
  border: none;
  width: 100%;
  background-color: #f7f7f7;
  color: #9b9b9b;
  font-size: 1.6rem;
  font-family: "pretendard-regular";
  resize: none;
  padding: 2rem;
  box-sizing: border-box;
  height: 85%;
}

.textarea::-webkit-scrollbar {
  display: none;
}

.textarea:focus {
  outline: none;
}

.submitBtn {
  display: block;
  border-radius: 1.5rem;
  background-color: #ff6e05;
  color: #fff;
  margin: 2.9rem auto 3rem;
  padding: 1.1rem 0;
  width: 58%;
  font-size: 1.5rem;
  cursor: pointer;
}

.submitBtn.disabled {
  background-color: #5d5d5d;
  cursor: initial;
}

.wordCountTag {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  color: #9b9b9b;
  font-size: 1.4rem;
}

/* building bg */
.buildingBG {
  width: 100%;
  height: 30%;
  padding-top: 2rem;
  object-fit: none;
  position: relative;
  z-index: 1;
}

.note {
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: center;
  margin-top: 2rem;
  color: #7d7d7d;
}
