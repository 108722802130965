.card {
  background-color: #fff;
  box-shadow: 0 0.2rem 0.3rem 0.01rem rgba(0, 0, 0, 0.1);
  padding: 1.3rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  position: relative;
}

.cardCover {
  background-color: #f3f3f3;
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  height: 120px;
  margin-bottom: 1rem;
}

.cardCover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

.cardContents {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.cardTitle {
  /* margin-top: 0.8rem; */
  font-size: 1.6rem;
  font-weight: bold;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardDescription {
  font-size: 1.3rem;
  line-height: 1.3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.cardFooter {
  margin-top: auto;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
}

.role {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.cardRating {
  font-size: 1.4rem;
  border: 1px solid var(--gray-300);
  padding: 0.4rem 0.8rem;
  border-radius: 1.3rem;
  z-index: 2;
  bottom: 1.8rem;
  left: 50%;
  background-color: #ffffffc6;
  color: var(--gray-900);
  display: flex;
  gap: 0.6rem;
}

.ratingTitle {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 0.8rem;
}

.moreBtn {
  position: absolute;
  right: 0rem;
  bottom: 0;
  cursor: pointer;
}

.bmkBtn {
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  cursor: pointer;
}

.popover {
  background-color: #fff;
  box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 16rem;
  right: 2rem;
  border-radius: 0.4rem;
  overflow: hidden;
  z-index: 9999;
}

.popOverBtn {
  padding: 1rem 2rem;
  cursor: pointer;
  display: block;
}

.popOverBtn:hover {
  background-color: #f3f3f3;
}

.thumbIcon {
  width: 1.4rem;
}

@media (max-width: 380px) {
  .cardRating {
    font-size: 1.1rem;
  }
  .thumbIcon {
    width: 1.2rem;
  }
}
