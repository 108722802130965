.header {
  padding: 2.1rem 1rem;
  position: relative;
}

.headings {
  text-align: center;
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.settingBtn {
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-40%);
}

.contents {
  /* padding-bottom: 10rem; */
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  padding: 4rem 0;
}

.level {
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.3rem;
  color: #7e7e7e;
}

.name {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.navHeader {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: 1px solid #ededed;
  width: fit-content;
  margin: 0 auto 20px;
  border-radius: 2rem;
}

.navHeader > * {
  text-align: center;
  padding: 2rem 3rem;
  font-size: 1.3rem;
  border-radius: 2rem;
}

.active {
  background-color: #ff6e05;
  color: white;
}

.levelContainer {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.posts {
  font-size: 1.4rem;
}

.isEmpty {
  background-color: #fff;
  text-align: center;
  padding-top: 5rem;
}

.isNotEmpty {
  padding-bottom: 10rem;
  min-height: 70vh;
  background-color: #fff;
}

.hasOnePost {
  height: 70vh;
}

.header {
  border-bottom: 1px solid #c4c4c4;
}

.cardList {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 49%));
  gap: 1rem;
  padding: 0 2rem;
  background-color: #fff;
}
