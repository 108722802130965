.container {
  max-width: 41rem;
  background-color: #fff;
  margin: 0 auto;
  height: 100vh;
}

.navBar {
  border-top: 0.1rem solid #c5c5c5;
  padding: 1.5rem 0 1.2rem;
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  width: 41rem;
  display: flex;
  background-color: #fff;
  z-index: 1000;
}

.navBar a {
  text-decoration: none;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  color: var(--gray-600);
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.active {
  color: #ff6e05;
}

@media (max-width: 410px) {
  .navBar {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .navBar {
    padding: 1rem 0 1.2rem;
  }

  .navBar a {
    font-size: 1rem;
  }
}
